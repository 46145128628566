import { TimelineMax } from 'gsap';
import Lerper from '../utils/lerper';

jQuery(($) => {
  $('[data-role*="switch-language"]').each((index, switchLanguage) => {
    const $self = $(switchLanguage);
    const $items = $self.find('span, a');
    let $active = $items.filter('.active');
    const $circle = $('<span class="circle"></span>').css('opacity', 0).appendTo($self);
    const circleWidth = $circle.width();
    const tl = new TimelineMax();
    const left = new Lerper({
      damping: 0.09,
      initialValue: 0,
      onTick(value) {
        tl.set($circle, { x: value });
      },
    });

    $self.mousemove(({ pageX, target }) => {
      const [hoveredItem] = $items.filter(target).toArray() || [];
      if (hoveredItem) {
        left.setValue(hoveredItem.offsetLeft);
      } else {
        left.setValue(pageX - $self.offset().left - (0.5 * circleWidth));
      }
    });

    $self.mouseleave(() => {
      left.setValue($active[0].offsetLeft);
    });

    $items.click(({ target }) => {
      $active.removeClass('active');
      $active = $(target);
      $active.addClass('active');
    });

    $self.on('init', () => {
      const initTl = new TimelineMax();
      left.setValue($active[0].offsetLeft);
      initTl.to($circle, 0.4, {
        clearProps: 'all',
        opacity: 1,
      });
    });
  });
});
