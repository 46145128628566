import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';
import { addParallaxBackground } from '../swiper-mixins';

jQuery(($) => {
  $('[data-role*="cars-slider"]').each((index, carsSlider) => {
    const swiperContainer = carsSlider.querySelector('.swiper-container');
    const prevEl = carsSlider.querySelector('.swiper-button-prev');
    const nextEl = carsSlider.querySelector('.swiper-button-next');
    const swiper = new Swiper(swiperContainer, {
      breakpoints: {
        1024: {
          spaceBetween: 30,
        },
      },
      navigation: {
        prevEl,
        nextEl,
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      simulateTouch: false,
      slidesPerView: 1,
      spaceBetween: 90,
      speed: 750,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    });

    objectFitImages(carsSlider.querySelectorAll('img'));

    addParallaxBackground(swiper, carsSlider);
  });
});
