import { ROOT_ELEMENT } from '../constants';

jQuery(($) => {
  $('[data-role*="redirect-timeout"]').first().each((i, el) => {
    const { redirectTo, redirectTimeout } = $(el).data();

    if (redirectTo && redirectTimeout) {
      ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
        setTimeout(() => {
          window.location = redirectTo;
        }, redirectTimeout);
      });
    }
  });
});
