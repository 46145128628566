import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';

jQuery(($) => {
  $('[data-role*="used-car-slider"]').each((index, usedCarSlider) => {
    const swiperContainer = usedCarSlider.querySelector('.swiper-container');
    const prevEl = usedCarSlider.querySelector('.swiper-button-prev');
    const nextEl = usedCarSlider.querySelector('.swiper-button-next');
    const paginationEl = usedCarSlider.querySelector('.swiper-pagination');
    const swiper = new Swiper(swiperContainer, {
      lazy: true,
      navigation: {
        nextEl,
        prevEl,
      },
      pagination: {
        clickable: true,
        el: paginationEl,
      },
      preloadImages: false,
      simulateTouch: false,
      slidesPerView: 1,
      spaceBetween: 1,
      speed: 750,
    });

    swiper.on('lazyImageReady', (sliderEl, imageEl) => {
      objectFitImages(imageEl);
    });
  });
});
