import Scrollmagic from 'scrollmagic';

jQuery(($) => {
  const $results = $('[data-role*="search-result"]');

  if (!$results.length) return;

  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 500);

  const controller = new Scrollmagic.Controller();

  $results.css({
    opacity: 0,
  });

  $results.each((index, searchResult) => {
    new Scrollmagic.Scene({
      reverse: false,
      triggerElement: searchResult,
      triggerHook: 0.7,
    })
      .addTo(controller)
      .setTween(searchResult, 0.3, {
        clearProps: 'all',
        opacity: 1,
      });
  });
});
