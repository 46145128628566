import { TimelineMax, TweenMax } from 'gsap';
import { Linear } from 'gsap/src/uncompressed/easing/EasePack';
import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';
import leadZero from '../utils/lead-zero';
import Lerper from '../utils/lerper';
import detectMobile from '../utils/detect-mobile';
import isMicrosoft from '../utils/detect-microsoft';
import { ROOT_ELEMENT } from '../constants';
import getShift from '../parallax';

const CLIP_PATH_ID = 'cover-slider-clip-path';
const CLIP_PATH = `url(#${CLIP_PATH_ID})`;
const CLIP_PATH_HEIGHT = 430;
const isMobile = detectMobile();
const AUTOPLAY_DELAY = 5000;

jQuery(($) => {
  $('[data-role*="cover-slider"]').each((index, coverSlider) => {
    const $self = $(coverSlider);
    const tl = new TimelineMax();

    // duplicate the layers
    const $imagesLayer = $self.find('.cover-slider__layer_to-copy');
    const $clippedLayer = $imagesLayer.clone()
      .html($imagesLayer.html())
      .insertAfter($imagesLayer);
    const sliderLength = $imagesLayer.find('.swiper-slide').length;

    if (!isMobile) {
      // start videos
      const videos = $self.find('video').toArray();
      const l = Math.ceil(videos.length / 2);
      const pairs = [];
      for (let i = 0; i < l; i += 1) {
        pairs.push([videos[i], videos[i + l]]);
      }
      pairs.forEach((videosArray) => {
        let loadedVideosCount = 0;

        function startVideos() {
          videosArray.forEach((video) => {
            video.muted = true;
            video.loop = true;
            video.play();
          });
        }

        videosArray.forEach((video) => {
          const onReady = () => {
            loadedVideosCount += 1;

            if (loadedVideosCount === videosArray.length) {
              startVideos();
            }
          };

          video.load();
          video.addEventListener('canplaythrough', onReady);
          if (video.readyState > 3) {
            onReady();
          }
        });
      });
    }

    // swiper carousels
    const $paginationEl = $self.find('.swiper-pagination');
    const paginationEl = $paginationEl[0];
    const [imagesContainer, clippedContainer, textsContainer] = $self.find('.swiper-container').toArray() || [];
    const swiperConfig = {
      simulateTouch: false,
    };
    const imagesSwiper = new Swiper(imagesContainer, {
      ...swiperConfig,
      breakpoints: {
        640: {
          spaceBetween: 30,
        },
      },
      spaceBetween: 250,
    });
    const clippedSwiper = new Swiper(clippedContainer, swiperConfig);
    tl.set(textsContainer, {
      zIndex: 2,
    });
    const textsSwiper = new Swiper(textsContainer, {
      ...swiperConfig,
      autoplay: {
        delay: AUTOPLAY_DELAY,
      },
      pagination: {
        el: paginationEl,
        clickable: true,
        renderBullet(i, className) {
          return `<span class="${className}">${leadZero(i + 1)}</span>`;
        },
      },
      spaceBetween: 500,
      speed: 1700,
    });

    imagesSwiper.controller.control = clippedSwiper;
    clippedSwiper.controller.control = imagesSwiper;
    textsSwiper.on('progress', (progress) => {
      imagesSwiper.slideTo(progress * (sliderLength - 1), 1300, false);
    });

    // apply a clip path
    const notText = [imagesContainer, clippedContainer];
    if (isMicrosoft) {
      $clippedLayer.hide();
    } else {
      tl.set($imagesLayer, {
        autoAlpha: 0.8,
      });

      tl.set($clippedLayer, {
        clipPath: CLIP_PATH,
        webkitClipPath: CLIP_PATH,
        width: '200%',
        x: -6,
        y: -6,
        zIndex: 2,
      });

      // horizontally move the clipped slider
      const lerper = new Lerper({
        damping: 0.05,
        initialValue: 0,
        onTick(x) {
          tl.set($clippedLayer, {
            x: -6 - (0.5 * window.innerWidth * x),
          });
          tl.set(clippedContainer, {
            x: 0.5 * window.innerWidth * x,
          });
        },
      });

      if (sliderLength > 1) {
        // if there's a slider, move LAMBORGHINI when slides are switching
        imagesSwiper.on('progress', (progress) => {
          lerper.setValue(progress);
        });
      } else {
        // if there's no slider, move LAMBORGHINI when scrolling
        // and also apply vertical parallax to image
        const parallaxTl = new TimelineMax();
        const parallaxLerper = new Lerper({
          damping: 0.18,
          initialValue: -getShift(coverSlider, 0.1),
          onTick(x) {
            parallaxTl.set(notText, { y: 0.3 * x });
          },
        });
        window.addEventListener('scroll', () => {
          const shiftPixels = -getShift(coverSlider, 0.1);
          lerper.setValue(0.001 * shiftPixels);
          parallaxLerper.setValue(shiftPixels);
        });
      }
    }

    // add a razor text appear effect and image scale on each slide
    const $textSlides = $(textsContainer).find('.swiper-slide');
    $textSlides.curtainSection({ useScrollMagic: false });
    const $notTextSlides = $(notText).find('.swiper-slide');

    TweenMax.set($notTextSlides.children(), {
      transformOrigin: '50vw 50vh',
      scale: 1.15,
    });

    function animateSlidesRazor() {
      $textSlides.filter('.swiper-slide-active').trigger('run');
      $textSlides.not('.swiper-slide-active').trigger('rewind');
    }

    function animateSlidesScale() {
      TweenMax.set($notTextSlides.not('.swiper-slide-active').children(), { scale: 1.15 });
      TweenMax.to($notTextSlides.filter('.swiper-slide-active').children(), 0.001 * AUTOPLAY_DELAY, {
        ease: Linear.easeNone,
        scale: 1,
      });
    }

    textsSwiper.on('slideChangeTransitionStart', () => {
      setTimeout(() => {
        animateSlidesRazor();
        animateSlidesScale();
      }, 600);
    });

    textsSwiper.autoplay.stop();
    ROOT_ELEMENT.addEventListener('preloaderFadeoutStart', () => {
      animateSlidesScale();
    });
    ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
      textsSwiper.autoplay.start();
      animateSlidesRazor();
    });

    // IE object fit
    objectFitImages(coverSlider.querySelectorAll('.swiper-slide img'));
    objectFitVideos(coverSlider.querySelectorAll('.swiper-slide video'));
  });

  // vertically align clip path element
  (() => {
    const clipPath = document.getElementById(CLIP_PATH_ID);
    if (!clipPath) return;
    const tl = new TimelineMax();
    function verticallyAlignClipPath() {
      tl.set(clipPath, {
        attr: {
          transform: `translate(0, ${0.5 * (window.innerHeight - CLIP_PATH_HEIGHT)})`,
        },
      });
    }
    verticallyAlignClipPath();
    window.addEventListener('resize', verticallyAlignClipPath);
  })();
});
