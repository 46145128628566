import { TweenMax } from 'gsap';
import GoogleMapsLoader from 'google-maps';
import styles from './google-map-styles.json';
import '../../images/lamborghini-marker.png';
import msVersion from '../utils/detect-microsoft';

jQuery(($) => {
  $('[data-role*="google-map"]')
    .filter((i, el) => /google-map[^-]/.test($(el).data('role')))
    .each((index, googleMap) => {
      const $self = $(googleMap);
      const $toggles = $self.find('[data-role*="google-map-toggles"] li');
      $toggles.find('a').click(e => e.preventDefault());

      const $tabs = $self.find('[data-role*="specifications-table-tabs"]');
      const $otherTabs = $self.find('[data-role*="google-map-tab"]');
      const $map = $self.find('[data-role*="google-map-container"]');
      TweenMax.set($otherTabs.children(), { autoAlpha: 0, x: 30 });
      $tabs.on('leave', (e, i) => {
        $toggles.eq(i).removeClass('opened');
        TweenMax.staggerTo($otherTabs.eq(i).children(), 0.4, {
          autoAlpha: 0,
          x: -60,
        }, 0.1, () => {
          TweenMax.set($otherTabs.eq(i).children(), { x: 60 });
        });
      });
      $tabs.on('enter', (e, i) => {
        $toggles.eq(i).addClass('opened');
        $map.trigger('gotoMarkerIndex', [i]);
        TweenMax.staggerTo($otherTabs.eq(i).children(), 0.4, {
          autoAlpha: 1,
          clearProps: 'all',
          x: 0,
        }, 0.1);
      });

      /* eslint-disable func-names */
      $toggles.click(function () {
        $tabs.trigger('setActiveTab', [$toggles.index(this)]);
      });

      $toggles.eq(0).addClass('opened');
      TweenMax.set($otherTabs.eq(0).children(), { autoAlpha: 1, x: 0 });

      // directions
      const $modalContents = $self.find('[data-role="google-map-modal-contents"]');
      const $modal = $.createModal($modalContents.html());
      const $searchbox = $modal.find('[data-role="google-map-searchbox"]');
      $searchbox.on('googleLoaded', (e, google) => {
        const searchbox = new google.maps.places.SearchBox($searchbox[0]);
        searchbox.addListener('places_changed', () => {
          const [place] = searchbox.getPlaces() || [];

          if (!place) return;

          const { geometry: { location } } = place;

          $modal.trigger('toggle', [false]);

          $map.trigger('getDirections', location);
        });
      });

      const $toggle = $self.find('[data-role="google-map-modal-toggle"]');
      $toggle.click(() => {
        $modal.trigger('toggle', [true]);

        if (!msVersion || msVersion >= 12) {
          $searchbox.focus(); // placeholder should not disappear in IE11 and lower
        }
      });
    });
});


jQuery(($) => {
  const $maps = $('[data-role*="google-map-container"]');
  const $searchboxes = $('[data-role*="google-map-searchbox"]');

  if (!$maps.length && !$searchboxes.length) return;

  GoogleMapsLoader.KEY = window.GOOGLE_MAPS_KEY;
  GoogleMapsLoader.LIBRARIES = ['places'];
  GoogleMapsLoader.VERSION = 'weekly';

  GoogleMapsLoader.load((google) => {
    $maps.each((index, googleMapContainer) => {
      const $self = $(googleMapContainer);
      const { markersJson } = $self.data();
      const markersJsonFixed = markersJson.replace(/'/g, '"');
      const markers = JSON.parse(markersJsonFixed);

      let activeIndex = 0;

      const map = new google.maps.Map(googleMapContainer, {
        ...markers[activeIndex],
        styles,
        disableDefaultUI: true,
      });

      markers.forEach(({ center }) => {
        new google.maps.Marker({
          icon: $self.data('markerIcon'),
          map,
          position: center,
        });
      });

      $self.on('gotoMarkerIndex', (e, i) => {
        map.setZoom(markers[i].zoom);
        setTimeout(() => {
          map.panTo(markers[i].center);
        }, 100);
        activeIndex = i;
      });

      // directions
      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer();
      directionsDisplay.setMap(map);

      $self.on('getDirections', (e, origin) => {
        directionsService.route({
          origin,
          destination: markers[activeIndex].center,
          travelMode: 'DRIVING',
        }, (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          }
        });
      });
    });

    $searchboxes.trigger('googleLoaded', [google]);
  });
});
