import videojs from 'video.js';

jQuery(($) => {
  /* eslint-ignore func-names */
  $.fn.videojsCustom = function ({ videojsOptions } = {}) {
    return this.each((index, video) => {
      const options = Object.assign({
        controls: true,
      }, videojsOptions);

      const player = videojs(video, options);

      const $playerEl = $(player.el());

      $playerEl
        .addClass('video-js play-button-hover-zone')
        .find('.vjs-big-play-button')
        .attr('class', 'play-button')
        .html('');
    });
  };

  $('[data-role*="videojs"]').videojsCustom();
});
