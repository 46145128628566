import { TweenMax } from 'gsap';
import createEvent from '../utils/create-event';

/*
Debounce resize event
 */
let timeout;
window.addEventListener('resize', () => {
  if (timeout) {
    clearTimeout(timeout);
  }

  setTimeout(() => {
    window.dispatchEvent(createEvent('resizeEnd'));
  }, 150);
});


/**
 * Animate full height cover
 */
jQuery(($) => {
  let oldWidth;
  const $elements = $('.cover-slider video, .cover-slider img');

  function resizeElements() {
    oldWidth = window.innerWidth;
    TweenMax.to($elements, 0.7, {
      height: Math.max(window.innerHeight, 550),
    });
  }

  resizeElements();

  window.addEventListener('resizeEnd', () => {
    if (oldWidth !== window.innerWidth) {
      resizeElements();
    }
  });
});
