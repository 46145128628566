import { TimelineMax } from 'gsap';
import store from 'store';
import toggleScroll from '../utils/toggle-body-scroll';
import createEvent from '../utils/create-event';
import { ROOT_ELEMENT } from '../constants';

const STORAGE_KEY = 'hasSeenPreloader';
const STORAGE_VALUE = 'true';

window.addEventListener('load', () => {
  store.set(STORAGE_KEY, STORAGE_VALUE);
});

document.addEventListener('DOMContentLoaded', () => {
  const tl = new TimelineMax({
    onComplete() {
      ROOT_ELEMENT.dispatchEvent(createEvent('preloaderEnd')); // used in cookies message
    },
  });

  const haveSeenPreloader = store.get(STORAGE_KEY) === STORAGE_VALUE;

  toggleScroll(false);

  if (!haveSeenPreloader) {
    toggleScroll(true);

    tl.to('.preloader .progress', 2.5, {
      scaleX: 1,
    }, '+=2');
  }

  tl.to('.preloader', 0.6, {
    autoAlpha: 0,
    onStart: () => {
      ROOT_ELEMENT.dispatchEvent(createEvent('preloaderFadeoutStart'));
    },
    onComplete: () => {
      toggleScroll(false);
    },
  }, '+=1.5');
});
