import Scrollmagic from 'scrollmagic';
import { TweenMax } from 'gsap';
import Lerper from '../utils/lerper';

function getNumberOfDigitsAfterDecimal(number) {
  const [, round] = String(number).split('.') || [];

  if (!round) return 0;

  return round.length;
}

jQuery(($) => {
  const $sections = $('[data-role*="short-specs"]');

  if (!$sections.length) return;

  const controller = new Scrollmagic.Controller();

  $sections.each((index, section) => {
    const $self = $(section);
    const $wrappers = $self.find('.short-spec');
    const $tweenableNumbers = $self.find('[data-role="spec-number"]');
    const $lines = $self.find('[data-role="spec-lines"]');
    const scene = new Scrollmagic.Scene({
      reverse: false,
      triggerElement: section,
      triggerHook: 0.8,
    }).addTo(controller);

    const values = $tweenableNumbers.toArray().map(el => ({ value: Number(el.innerHTML) }));

    $tweenableNumbers.html('0');
    TweenMax.set($wrappers, {
      autoAlpha: 0,
      y: 15,
    });
    TweenMax.set($lines.children(), {
      autoAlpha: 0,
      y: 15,
    });

    scene.on('enter', () => {
      values.forEach((el, i) => {
        const digitsAfterDecimal = getNumberOfDigitsAfterDecimal(el.value);
        let precision = 1;
        for (let j = 0; j < digitsAfterDecimal; j += 1) {
          precision *= 0.1;
        }

        setTimeout(() => {
          TweenMax.to($wrappers[i], 0.3, {
            autoAlpha: 1,
            clearProps: 'all',
            y: 0,
          });
          const lerper = new Lerper({
            damping: 0.12,
            initialValue: 0,
            onComplete() {
              TweenMax.staggerTo($lines.eq(i).children(), 0.5, {
                autoAlpha: 1,
                clearProps: 'all',
                y: 0,
              }, 0.2);
            },
            onTick(value) {
              $tweenableNumbers[i].innerHTML = value.toFixed(digitsAfterDecimal);
            },
            precision,
          });

          lerper.setValue(el.value);
        }, 200 * i);
      });
    });
  });
});
