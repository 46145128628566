/**
 * Applies curtain revealing effect to all headings in the section upon user scrolls to it
 * by adding CSS class names.
 *
 * The code is wrapped as jQuery plugin to re-apply the effect from other plugins. Other plugins
 * may trigger events 'rewind' and 'run' via $.fn.trigger
 */

import { TimelineMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ROOT_ELEMENT } from './constants';

jQuery(($) => {
  const controller = new ScrollMagic.Controller();

  function curtainSectionPlugin(options = {}) {
    const { elements, useScrollMagic = true } = options;
    return this.each((index, curtainSection) => {
      const $self = $(curtainSection);
      const $elements = elements || $self.find('.h1, .h2, .h3, .h4, .h5, .h6, .more')
        .filter((i, el) => $(el).data('role') !== 'last-word-of-brand-color')
        .add($self.find('[data-role="last-word-of-brand-color"] span'));

      const tl = new TimelineMax();

      function rewind() {
        $elements.removeClass('reveal').addClass('curtain hide');
      }

      function run() {
        tl.staggerTo($elements, 0, {
          onComplete() {
            const { target } = this;
            target.classList.remove('hide');
            target.classList.add('reveal');
          },
        }, 0.1, '+=0.2');
      }

      rewind();

      if (useScrollMagic) {
        const scene = new ScrollMagic.Scene({
          reverse: false,
          triggerElement: curtainSection,
          triggerHook: 0.6,
        })
          .addTo(controller);

        ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
          if (scene.state() === 'BEFORE') {
            scene.on('start', run);
          } else {
            run();
          }
        });
      }

      $self.on('rewind', rewind);

      $self.on('run', run);
    });
  }

  jQuery.fn.curtainSection = curtainSectionPlugin;

  $('[data-role*="curtain-section"]').curtainSection();
});
