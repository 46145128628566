/**
 * Applies correct color and font weight to text content of editable elements.
 *
 * Transforms following HTML:
 * <div class="n7" data-role="last-word-of-brand-color">
 *  <h2>Hello World!</h2>
 * </div>
 *
 * Into following HTML:
 * <div class="n7" data-role="last-word-of-brand-color">
 *  <h2>
 *    <span>Hello</span> <span class="color-brand n3">World!</span>
 *  </h2>
 * </div>
 */
jQuery(($) => {
  $('[data-role*="last-word-of-brand-color"]').each((index, lastWordWidget) => {
    const $self = $(lastWordWidget);
    const { brandClass } = $self.data();
    $self.children().each((j, el) => {
      const words = $(el).text().trim().split(' ');
      $(el).html(words.map((word, i) =>
        `<span class="${((i === words.length - 1 && i > 0) ? `color-brand ${brandClass}` : '')}">${word}</span>`).join(' '));
    });
  });
});
