import { TweenLite } from 'gsap';

const $value = new WeakMap();
const $lerpedValue = new WeakMap();
const $isComplete = new WeakMap();

export default class {
  constructor(params) {
    const {
      damping = 0.07,
      initialValue = 0,
      onComplete = () => {},
      onTick = () => {},
      precision = 0.001,
    } = params;

    this.setValueImmediately(initialValue);

    TweenLite.ticker.addEventListener('tick', () => {
      if ($isComplete.get(this)) return;

      const value = $value.get(this);
      let lerpedValue = $lerpedValue.get(this);

      const delta = value - lerpedValue;
      if (Math.abs(delta) < precision) {
        $lerpedValue.set(this, value);
        onTick(value);
        onComplete(value);
        $isComplete.set(this, true);
      } else {
        lerpedValue += (value - lerpedValue) * damping;
        onTick(lerpedValue);
        $lerpedValue.set(this, lerpedValue);
      }
    });
  }

  setValue(_value) {
    $isComplete.set(this, false);
    $value.set(this, _value);
    return this;
  }

  setValueImmediately(_value) {
    this.setValue(_value);
    $lerpedValue.set(this, _value);
    return this;
  }

  getValue() {
    return $value.get(this);
  }
}
