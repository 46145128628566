import { TimelineMax } from 'gsap';

jQuery(($) => {
  /**
   * connection with toggles of this specific block
   */
  $('[data-role*="specifications-table"]').each((index, specsTable) => {
    const $self = $(specsTable);
    const $toggles = $self.find('.models-gallery__list .has-children ul a')
      .each((i, el) => $(el).html(`<span>${el.innerHTML}</span>`));
    const $tabs = $self.find('[data-role*="specifications-table-tabs"]');

    /* eslint-disable func-names */
    $toggles.click(function (e) {
      e.preventDefault();
      $tabs.trigger('setActiveTab', [$toggles.index(this)]);
    });

    $tabs.on('leave', (e, i) => {
      $toggles.eq(i).removeClass('active');
    });

    $tabs.on('enter', (e, i) => {
      $toggles.eq(i).addClass('active');
    });
  });

  /**
   * tabs animation - can be reused with different toggling system via jQuery setActiveTab event
   */
  $('[data-role*="specifications-table-tabs"]').each((index, tabs) => {
    const $self = $(tabs);
    const $tabs = $self.find('.spec-tab');

    let busy = false;

    const tl = new TimelineMax({
      onComplete() {
        busy = false;
      },
    });
    tl.set($tabs.children(), {
      transformOrigin: 'right center',
      scaleX: 0,
    });

    let indexOfActive = 0;
    tl.set($tabs.eq(indexOfActive).children(), {
      clearProps: 'all',
      scaleX: 1,
    });
    $self.trigger('enter', [indexOfActive]);

    function setActiveTab(_indexOfActive) {
      if (busy) return;
      busy = true;

      const $tabsToShow = $tabs.eq(_indexOfActive);
      const $tabsToHide = $tabs.eq(indexOfActive);

      $self.trigger('leave', [indexOfActive]);

      tl.add('hide');

      tl.to($tabsToHide.find('.spec-feature__name'), 0.4, {
        autoAlpha: 0,
        x: -60,
      }, 'hide');

      tl.to($tabsToHide.find('.spec-feature__value'), 0.4, {
        autoAlpha: 0,
        x: -30,
      }, 'hide');

      tl.set($tabsToHide.children(), {
        transformOrigin: 'left center',
      });

      tl.staggerTo($tabsToHide.children(), 0.4, {
        onComplete() {
          tl.set($tabsToHide.children(), {
            transformOrigin: 'right center',
          });
          $self.trigger('enter', [_indexOfActive]);
        },
        scaleX: 0,
      }, 0.05);


      tl.set($tabsToShow.find('.spec-feature__name'), {
        autoAlpha: 0,
        x: 30,
      });

      tl.set($tabsToShow.find('.spec-feature__value'), {
        autoAlpha: 0,
        x: 60,
      });

      tl.staggerTo($tabsToShow.children(), 0.4, {
        clearProps: 'all',
        scaleX: 1,
      }, 0.05);

      tl.add('show');

      tl.fromTo($tabsToShow.find('.spec-feature__name'), 0.4, {
        autoAlpha: 0,
        x: 60,
      }, {
        autoAlpha: 1,
        clearProps: 'all',
        x: 0,
      }, 'show');

      tl.fromTo($tabsToShow.find('.spec-feature__value'), 0.4, {
        autoAlpha: 0,
        x: 120,
      }, {
        autoAlpha: 1,
        clearProps: 'all',
        onComplete() {
          indexOfActive = _indexOfActive;
        },
        x: 0,
      }, 'show');
    }

    $self.on('setActiveTab', (e, _indexOfActive) => {
      setActiveTab(_indexOfActive);
    });
  });
});
