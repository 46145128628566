import { TimelineMax } from 'gsap';
import toggleBodyScroll from './utils/toggle-body-scroll';
import { ROOT_ELEMENT } from './constants';

jQuery(($) => {
  function createModal(html) {
    const $modal = $('<div />')
      .addClass('modal flex-container align-center align-middle')
      .appendTo(ROOT_ELEMENT)
      .html(html);

    const tl = new TimelineMax();

    tl.set($modal, { x: '-100%' });

    const $headerRight = $('.header-right');
    const $hamburger = $('[data-role="hamburger"]');

    function closeModal() {
      $modal.trigger('toggle', [false]);
    }

    function toggle(bool) {
      toggleBodyScroll(bool);
      $modal.toggleClass('show', bool);
      $headerRight.toggleClass('hide', bool);
      if (bool) {
        $hamburger.data('eventsDisabled', true)
          .trigger('toggle')
          .on('click', closeModal);
      } else {
        $hamburger.off('click', closeModal)
          .trigger('toggle')
          .data('eventsDisabled', false);
      }
      tl.to($modal, 0.6, {
        x: bool ? '0%' : '-100%',
      });
      tl.to($headerRight, 0.4, {
        x: bool ? '100%' : '0%',
      }, '-=0.3');
    }

    $modal.on('toggle', (e, bool) => {
      toggle(bool);
    });

    return $modal;
  }

  $.createModal = createModal;
});
