/*
 * This is a single webpack entry point for all scripts and also for CSS.
 * Also contains small initializations that don't require a separate file.
 */

import loadFonts from './utils/load-fonts';
import './elements/preloader';
import './elements/cookies';
import './last-word-of-brand-color';
import './curtain';
import './blocks/switch-language';
import './blocks/cover-slider';
import './article';
import './elements/cover';
import './elements/header';
import './blocks/recent-news';
import './segmenter';
import './create-modal';
import './blocks/segmented-video';
import './blocks/models-gallery';
import './parallax';
import './blocks/scroll-once';
import './blocks/cars-slider';
import './blocks/segmented-slider';
import './blocks/cars-model-slider';
import './blocks/short-specs';
import './blocks/specifications-table';
import './blocks/used-car-slider';
import './blocks/history-slider';
import './blocks/google-map';
import './blocks/redirect-timeout';
import './search-results';
import './videojs';
import './input-with-dropdown';
import './blocks/search-filters';
import './grain';
import '../styles/main.scss';
import '../fonts/BebasNeue-Regular.ttf';
import '../fonts/BebasNeue-Bold.ttf';
import '../fonts/BebasNeue-Light.ttf';

jQuery(($) => {
  loadFonts(['Roboto:300,400,500,700:latin']);

  $('[data-role="current-year"]').text((new Date()).getFullYear());

  $('img').attr('draggable', false);

  $('.button, .more').each((i, el) => $(el).html(`<span>${el.innerHTML}</span>`));
});
