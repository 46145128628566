import Swiper from 'swiper';

jQuery(($) => {
  $('[data-role*="segmented-slider"]').each((index, segmentedSlider) => {
    const swiperContainer = segmentedSlider.querySelector('.swiper-container');
    const prevEl = segmentedSlider.querySelector('.swiper-button-prev');
    const nextEl = segmentedSlider.querySelector('.swiper-button-next');
    const swiper = new Swiper(swiperContainer, {
      navigation: {
        nextEl,
        prevEl,
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      simulateTouch: false,
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 750,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    });

    // restart segmenter animation
    const $slides = $(swiperContainer).find('.swiper-slide');

    function restartSegmenters() {
      $slides.filter('.swiper-slide-active')
        .find('[data-role="segmenter"]')
        .html('')
        .segmenter((_segmenter) => {
          setTimeout(() => {
            _segmenter.animate();
          }, 300);
        });
    }

    swiper.on('slideChangeTransitionStart', restartSegmenters);
  });
});
