import Swiper from 'swiper';
import { addParallaxBackground, addPaginationParallax } from '../swiper-mixins';

jQuery(($) => {
  $('[data-role*="cars-model-slider"]').each((index, carsModelSlider) => {
    const swiperContainer = carsModelSlider.querySelector('.swiper-container');
    const prevEl = carsModelSlider.querySelector('.swiper-button-prev');
    const nextEl = carsModelSlider.querySelector('.swiper-button-next');
    const paginationEl = carsModelSlider.querySelector('.swiper-pagination');
    const $slides = $(swiperContainer).find('.swiper-slide');
    const swiper = new Swiper(swiperContainer, {
      breakpoints: {
        640: {
          spaceBetween: 15,
        },
      },
      centeredSlides: true,
      navigation: {
        nextEl,
        prevEl,
      },
      pagination: {
        clickable: true,
        el: paginationEl,
        renderBullet: (i, className) => `<span class="${className}">${$slides.eq(i).data('title')}</span>`,
      },
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 750,
    });

    addParallaxBackground(swiper, carsModelSlider);
    addPaginationParallax(swiper, paginationEl);
  });
});
