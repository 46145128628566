import ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenLite } from 'gsap';
import objectFitVideos from 'object-fit-videos';
import videojs from 'video.js';
import detectMobile from '../utils/detect-mobile';

const isMobile = detectMobile();

jQuery(($) => {
  const $videos = $('[data-role*="segmented-video"]');

  if (!$videos.length) return;

  const controller = new ScrollMagic.Controller();

  const $modal = $.createModal();

  $videos.each((index, modelVideo) => {
    const $self = $(modelVideo);
    const $thumb = $self.find('.segmented-video__thumb');
    const $segmenter = $thumb.find('[data-role="segmenter"]');
    const $video = $thumb.find('video');
    const video = $video[0];

    TweenLite.set(modelVideo, {
      autoAlpha: 0,
    });

    TweenLite.set($segmenter, {
      autoAlpha: 1,
    });

    const scene = new ScrollMagic.Scene({
      reverse: false,
      triggerElement: modelVideo,
      triggerHook: 0.7,
    }).addTo(controller);
    scene.on('enter', () => {
      TweenLite.to(modelVideo, 0.3, {
        autoAlpha: 1,
        clearProps: 'all',
      });
      if (!isMobile) {
        // lazy load
        video.load();
      }
    });

    // hover animation
    if (!isMobile) {
      let isPlayerBusy = false;
      let wasPaused = false;
      const tl = new TimelineMax();

      $thumb.hover(() => {
        const { segmenter } = $segmenter[0];
        const { options } = segmenter;
        options.animation.duration = 600;
        options.animation.translateZ = 0;
        options.shadowsAnimation.opacity = 0;
        segmenter.active = false;
        segmenter.animate();
        options.shadowsAnimation.opacity = 1;

        tl.to($segmenter, 0.4, {
          autoAlpha: 0,
          onComplete() {
            if (isPlayerBusy || isMobile) return;
            isPlayerBusy = true;
            const callback = () => {
              isPlayerBusy = false;

              if (wasPaused) {
                video.pause();
                wasPaused = false;
              }
            };
            const promise = video.play();
            if (promise) {
              promise.then(callback);
            } else {
              callback();
            }
          },
        }, '+=0.2');
      }, () => {
        tl.to($segmenter.add($video), 0.3, {
          autoAlpha: 0,
          onComplete() {
            $segmenter.html('').segmenter((segmenter) => {
              if (!isMobile) {
                if (isPlayerBusy) {
                  wasPaused = true;
                } else {
                  video.pause();
                  video.currentTime = 0;
                }
              }

              tl.to($segmenter, 0.3, {
                autoAlpha: 1,
                onComplete() {
                  segmenter.animate();
                  TweenLite.set($video, { autoAlpha: 1 });
                },
              });
            });
          },
        });
      });
    }

    // ie object fit
    objectFitVideos(video);

    // full video player opening on click
    const { fullVideoSrc, fullVideoPoster } = $self.data();
    let busy = false;
    $thumb.click(() => {
      if (busy) return;

      busy = true;

      const fullVideo = document.createElement('video');
      const source = document.createElement('source');
      source.src = fullVideoSrc;
      fullVideo.appendChild(source);
      $modal.append(fullVideo);
      $(fullVideo).videojsCustom({
        videojsOptions: {
          autoplay: true,
          poster: fullVideoPoster,
        },
      });

      const player = videojs.getPlayer(fullVideo);
      const playerEl = player.el();
      const fullVideoTl = new TimelineMax();
      fullVideoTl.set(playerEl, { autoAlpha: 0, x: -30 });
      fullVideoTl.to(playerEl, 0.5, {
        autoAlpha: 1,
        onComplete() {
          busy = false;
        },
        x: 0,
      }, '+=0.7');
      $modal
        .trigger('toggle', [true])
        .one('toggle', () => {
          fullVideoTl.to(fullVideo, 0.3, {
            autoAlpha: 0,
            onComplete() {
              player.dispose();
            },
            x: -30,
          });
        });
    });
  });
});
