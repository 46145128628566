/**
 * Mixins that re-use common pieces of behavior between different swiper
 * configurations used in projects
 */

import { TimelineMax } from 'gsap';
import Lerper from './utils/lerper';

// horizontally move parallax background
export function addParallaxBackground(swiper, container) {
  const tl = new TimelineMax();
  const lamborghiniParallax = container.querySelector('.lamborghini-parallax');
  const lerper = new Lerper({
    damping: 0.05,
    initialValue: 0,
    onTick(x) {
      tl.set(lamborghiniParallax, {
        x: -6 - (0.5 * window.innerWidth * x),
      });
    },
  });

  swiper.on('progress', (progress) => {
    lerper.setValue(progress);
  });
}

// horizontally move pagination along with slider
export function addPaginationParallax(swiper, paginationEl) {
  const tl = new TimelineMax();
  const bullets = $(paginationEl).children().toArray();
  const firstBullet = bullets[0];
  const lastBullet = bullets.pop();
  const lerper = new Lerper({
    damping: 0.12,
    initialValue: 0,
    onTick(x) {
      const offset = (0.5 * firstBullet.clientWidth) +
        (x * (paginationEl.scrollWidth - lastBullet.clientWidth));

      tl.set(paginationEl, {
        force3D: true,
        x: -offset,
      });
    },
    precision: 0.0005,
  });

  swiper.on('progress', (progress) => {
    lerper.setValue(progress);
  });
}
