/**
 * Segmented image effect wrapped into jQuery plugin
 */

import ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';
import './lib/segmenter';
import MobileDetect from 'mobile-detect';

function toPercents(array) {
  return array.map((el) => {
    const res = {};
    Object.keys(el).forEach((key) => {
      res[key] = el[key] * 100;
    });
    return res;
  });
}

function spreadSegmentSet(width, height) {
  return {
    pieces: 5,
    positions: toPercents([
      {
        top: 0,
        left: 520 / 900,
        width: 40 / width,
        height: 40 / height,
      },
      {
        top: 1 - (40 / height),
        left: 0,
        width: 40 / width,
        height: 40 / height,
      },
      {
        top: 80 / height,
        left: 1 - (80 / width),
        width: 80 / width,
        height: 80 / height,
      },
      {
        top: 1 - (120 / height),
        left: 560 / 900,
        width: 120 / width,
        height: 120 / height,
      },
      {
        top: 0,
        left: 0,
        width: 240 / width,
        height: 240 / height,
      },
    ]),
  };
}

function spreadAlternativeSegmentSet(width, height) {
  return {
    pieces: 6,
    positions: toPercents([
      {
        top: 1 - (40 / height),
        left: 0,
        width: 40 / width,
        height: 40 / height,
      },
      {
        top: 0,
        left: 1 - (40 / width),
        width: 40 / width,
        height: 40 / height,
      },
      {
        top: 240 / 500,
        left: 100 / 900,
        width: 80 / width,
        height: 80 / height,
      },
      {
        top: 20 / 500,
        left: 0,
        width: 80 / width,
        height: 80 / height,
      },
      {
        top: 1 - (120 / height),
        left: 560 / 900,
        width: 120 / width,
        height: 120 / height,
      },
      {
        top: 0,
        left: 240 / width,
        width: 240 / width,
        height: 240 / height,
      },
    ]),
  };
}

const segmentsSets = {
  spread_16x9: spreadSegmentSet(960, 540),
  spread_21x9: spreadSegmentSet(1100, 450),
  spread_30x17: spreadSegmentSet(900, 510),
  'spread-alternative_16x9': spreadAlternativeSegmentSet(960, 540),
  'spread-alternative_21x9': spreadAlternativeSegmentSet(1100, 450),
  'spread-alternative_30x17': spreadAlternativeSegmentSet(900, 510),
  'two-layers': {
    pieces: 2,
    positions: [
      {
        top: 0,
        left: 0,
        width: 100 * (570 / 660),
        height: 100 * (640 / 730),
      },
      {
        top: 100 * (90 / 660),
        left: 100 * (90 / 730),
        width: 100 * (570 / 660),
        height: 100 * (640 / 730),
      },
    ],
  },
  'three-piece': {
    pieces: 2,
    positions: [
      {
        top: 0,
        left: 0,
        width: 100 * (240 / 775),
        height: 100 * (760 / 840),
      },
      {
        top: 100 * (80 / 660),
        left: 100 * (535 / 730),
        width: 100 * (240 / 775),
        height: 100 * (760 / 840),
      },
    ],
  },
};

const md = new MobileDetect(window.navigator.userAgent);
const useParallax = !md.mobile();

jQuery(($) => {
  const controller = new ScrollMagic.Controller();

  function segmenterPlugin(onReady) {
    return this.each((i, el) => {
      const segmentsSetKey = $(el).data('segmentsSet');

      const segmentsSet = segmentsSets[segmentsSetKey] || segmentsSets.spread_21x9;

      const tl = new TimelineMax();
      if (typeof onReady !== 'function') {
        tl.set(el, { autoAlpha: 0 });
      }

      const segmenter = new window.Segmenter(el, {
        ...segmentsSet,
        animation: {
          duration: 1200,
          easing: 'easeOutQuad',
          delay: 50,
          translateZ: 25,
        },
        onReady() {
          if (typeof onReady === 'function') {
            onReady(segmenter);
          } else {
            const scene = new ScrollMagic.Scene({
              reverse: false,
              triggerElement: el,
              triggerHook: 0.7,
            }).addTo(controller);

            scene.on('enter', () => {
              tl.to(el, 0.3, {
                autoAlpha: 1,
                onComplete() {
                  segmenter.animate();
                  el.classList.add('animated');
                },
              });
            });
          }
        },
        parallax: useParallax,
        parallaxListenerElement: $(el).closest('section')[0],
        parallaxMovement: { min: 2, max: 4 },
      });
    });
  }

  jQuery.fn.segmenter = segmenterPlugin;

  $('[data-role="segmenter"]').segmenter();
});
