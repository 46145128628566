/*
Connect model and brand inputs
 */

jQuery(($) => {
  $('[data-role="search-filters"]').each((index, searchFilters) => {
    const $self = $(searchFilters);
    const $brand = $self.find('[data-field="brand"]');
    const $model = $self.find('[data-field="model"]');
    const modelsByBrands = window.MODELS_BY_BRANDS;
    $brand.on('customChange', (e, value) => {
      let brandExists = false;

      for (let i = 0; i < modelsByBrands.length; i += 1) {
        const { id, models } = modelsByBrands[i];

        if (id === value) {
          const options = models.map(({ id: modelId, name }) => ({ value: modelId, label: name }));
          $model.trigger('setOptions', [options]);
          brandExists = true;
          break;
        }
      }

      if (!brandExists) {
        $model.trigger('setOptions', [[]]);
      }
    });
  });
});
