/*
Can be only one per page
 */

import Swiper from 'swiper';
import normalizeWheel from 'normalize-wheel';
import { TimelineMax } from 'gsap';
import Lerper from '../utils/lerper';
import { addPaginationParallax } from '../swiper-mixins';

const speed = 1500;

jQuery(($) => {
  const $historySlider = $('[data-role*="history-slider"]');
  const historySlider = $historySlider[0];

  if (!historySlider) return;

  const $self = $(historySlider);
  const masterSwiperContainer = $self.find('[data-role="history-slider-master"]')[0];
  const prevEl = historySlider.querySelector('.swiper-button-prev');
  const nextEl = historySlider.querySelector('.swiper-button-next');
  const paginationEl = historySlider.querySelector('.swiper-pagination');
  const $slides = $(masterSwiperContainer).find('.swiper-slide');
  const masterSwiper = new Swiper(masterSwiperContainer, {
    navigation: {
      prevEl,
      nextEl,
    },
    pagination: {
      clickable: true,
      el: paginationEl,
      renderBullet: (i, className) => `<span class="${className}">${$slides.eq(i).data('year')}</span>`,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 1200,
    speed,
    reverseDirection: true,
  });

  // change slides by scrolling up/down
  let isScrollBusy = false;
  window.addEventListener('wheel', (e) => {
    const { pixelY } = normalizeWheel(e);

    if (isScrollBusy) return;

    isScrollBusy = true;
    setTimeout(() => {
      isScrollBusy = false;
    }, speed + 100);

    if (pixelY > 0) {
      masterSwiper.slideNext(speed);
    }

    if (pixelY < 0) {
      masterSwiper.slidePrev(speed);
    }
  });

  // display year of the model changing on the background
  const $year = $(historySlider).find('[data-role*="history-slider-year"]');
  function getCurrentYear() {
    return $slides.filter('.swiper-slide-active').data('year');
  }
  const lerper = new Lerper({
    damping: 0.04,
    initialValue: getCurrentYear(),
    onTick(year) {
      $year.html(Math.round(year));
    },
  });
  masterSwiper.on('slideChangeTransitionStart', () => {
    lerper.setValue(getCurrentYear());
  });

  // pretty obvious but comment kept to match style
  addPaginationParallax(masterSwiper, paginationEl);

  // shift active pagination bullet and its closest siblings
  const $bullets = $(paginationEl).children();
  const bulletShiftTl = new TimelineMax();
  function shiftBullets() {
    const $active = $bullets.filter('.swiper-pagination-bullet-active');
    const index = $bullets.index($active);

    $bullets.each((i, el) => {
      const y = -45 / (1 + Math.abs(i - index));
      bulletShiftTl.set(el, {
        y,
      });
    });
  }
  shiftBullets();
  masterSwiper.on('slideChangeTransitionStart', shiftBullets);

  // add slave slider and connect with master
  const slaveSliderContainer = $self.find('[data-role="history-slider-slave"]')[0];
  const slaveSwiper = new Swiper(slaveSliderContainer, {
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    simulateTouch: false,
    slidesPerView: 1,
    speed,
  });
  masterSwiper.controller.control = slaveSwiper;
});
