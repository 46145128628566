import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';

jQuery(($) => {
  $('[data-role*="recent-news"]').each((index, recentNews) => {
    const swiperContainer = recentNews.querySelector('.swiper-container');
    const prevEl = recentNews.querySelector('.swiper-button-prev');
    const nextEl = recentNews.querySelector('.swiper-button-next');
    new Swiper(swiperContainer, {
      breakpoints: {
        420: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 2,
        },
      },
      navigation: {
        prevEl,
        nextEl,
      },
      simulateTouch: false,
      slidesPerView: 3,
      spaceBetween: 30,
      speed: 750,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    });

    objectFitImages(recentNews.querySelectorAll('img'));
  });
});
