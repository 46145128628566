import { TimelineMax } from 'gsap';
import PerfectScrollbar from 'perfect-scrollbar';
import detectMobile from './utils/detect-mobile';

const isMobile = detectMobile();

jQuery(($) => {
  $('[data-role*="input-with-dropdown"]').each((index, input) => {
    const $self = $(input);
    const $toggle = $self.children('.input-with-dropdown__toggle');
    const $dropdown = $self.children('.input-with-dropdown__dropdown');
    const $scroller = $dropdown.children('.input-with-dropdown__dropdown-scroller');
    const $arrow = $toggle.find('[class*="arrow"]');
    const $label = $toggle.find('label');
    const $text = $toggle.find('.input-with-dropdown__text');
    const $hiddenInput = $toggle.find('[type="hidden"]');
    let $select;
    const { fromTo } = $self.data();
    const hasFromTo = typeof fromTo !== 'undefined';
    const hasNativeSelect = isMobile && !hasFromTo;

    const tl = new TimelineMax();
    tl.set($dropdown, { autoAlpha: 0, scaleY: 0 });
    tl.set($label, { transformOrigin: 'left center' });

    let wasOpened = false;
    let isLabelUp = false;
    function toggle(_opened) {
      let isOpened;
      if (hasNativeSelect) {
        isOpened = !!$select.val() && $select.val() !== 'undefined';
      } else if (!hasFromTo) {
        isOpened = _opened || !!$hiddenInput.val();
      } else {
        isOpened = _opened || !!$text.text();
      }

      tl.add('start');
      if (!hasNativeSelect) {
        tl.to($arrow, 0.3, { scaleX: _opened ? -1 : 1 }, 'start');
        tl.to($dropdown, 0.3, { autoAlpha: +_opened, scaleY: +_opened }, 'start');
      }
      if (isLabelUp !== isOpened) {
        tl.to($label, 0.2, { scale: isOpened ? 0.8 : 1, y: isOpened ? -10 : 0 }, 'start');
        isLabelUp = isOpened;
      }
      wasOpened = _opened;
    }

    if (!hasNativeSelect) {
      $toggle.click(() => {
        if (hasFromTo && !wasOpened) {
          toggle(true);
        }
        if (!hasFromTo) {
          toggle(!wasOpened);
        }
      });

      document.body.addEventListener('click', ({ target }) => {
        if (!input.contains(target)) {
          toggle(false);
        }
      });
    }

    // options
    const { chooseValueText } = $self.data();
    if (hasNativeSelect) {
      $select = $(`<select name="${$hiddenInput.attr('name')}" class="input-with-dropdown__text"></select>`);
      $hiddenInput.replaceWith($select);
      $text.remove();
      $select.change(() => {
        const val = $select.val();
        toggle(false);
        $self.trigger('customChange', [val, $select.find(`[value="${val}"]`).text()]);
      });
    }

    function setOptions(options, newValue) {
      options.unshift({ label: hasNativeSelect ? '' : chooseValueText });
      if (hasNativeSelect) {
        $select.html(options.map(({ value, label }) => `<option value="${value}">${value ? label : ''}</option>`).join('\n'));
        $select.val(newValue).trigger('change');
      } else {
        $text.text('');
        $scroller.html('');
        $hiddenInput.val('');

        options.forEach(({ value, label }) => {
          const $option = $(`<a class="option" href="javascript: void null" data-value="${value}">${label}</a>`)
            .appendTo($scroller)
            .click(() => {
              const fixLabel = value ? label : '';
              $hiddenInput.val(value);
              $text.text(fixLabel);
              $scroller.find('.option').removeClass('active');
              $option.addClass('active');
              toggle(false);
              $self.trigger('customChange', [value, fixLabel]);
            });

          if (value === newValue) {
            $option.trigger('click');
          }
        });
      }
    }

    const { optionsJsonEscaped } = $self.data();
    const valueOnLoad = $hiddenInput.val();
    if (optionsJsonEscaped) {
      const optionsJson = optionsJsonEscaped.replace(/'/g, '"');
      const options = JSON.parse(optionsJson);

      setOptions(options, valueOnLoad);
    }

    // scrollbar for dropdown
    if (!hasFromTo) {
      new PerfectScrollbar($scroller[0], {
        suppressScrollX: true,
      });
    }

    // set options from outer code by passing event
    $self.on('setOptions', (e, options, newValue) => {
      setOptions(options, newValue);
      toggle(false);
    });

    // fromto
    const { fromText, toText } = $self.data();
    function updateText(from, to) {
      if (from && to) {
        $text.text(`${from} - ${to}`);
      } else if (from) {
        $text.text(`${fromText} ${from}`);
      } else if (to) {
        $text.text(`${toText} ${to}`);
      } else {
        $text.text('');
      }
      toggle(false);
    }

    if (hasFromTo) {
      const $childInputs = $self.find('[data-role*="input-with-dropdown"]');
      if ($childInputs.length !== 2) {
        throw new Error('input with dropdown with data-from-to should have exactly 2 child inputs with dropdown');
      }
      const $from = $childInputs.eq(0);
      const $to = $childInputs.eq(1);
      let from;
      let to;

      $from.on('customChange', (e, val, label) => {
        from = label;
        updateText(from, to);
      });
      $to.on('customChange', (e, val, label) => {
        to = label;
        updateText(from, to);
      });
    }
  });
});
