import { TimelineMax } from 'gsap';
import { Linear } from 'gsap/src/uncompressed/easing/EasePack';
import Lerper from '../utils/lerper';

jQuery(($) => {
  $('[data-role*="models-gallery"]').each((index, modelsGallery) => {
    const $modelsGallery = $(modelsGallery);
    const $list = $modelsGallery.find('.models-gallery__list');

    const $topLevel = $list.children().children();

    $topLevel.each((i, hasChildren) => {
      const $self = $(hasChildren);

      const tl = new TimelineMax();

      const lerper = new Lerper({
        damping: 0.1,
        initialValue: 0,
        onTick(value) {
          tl.set($self, { y: value });
        },
      });

      $self.on('move moveImmediately', (e, y) => {
        const value = lerper.getValue();
        if (e.type === 'moveImmediately') {
          lerper.setValueImmediately(value + y);
        } else {
          lerper.setValue(value + y);
        }
        $self.trigger(e.type.replace('move', 'moved'), y);
      });
    });

    const listTl = new TimelineMax();
    const listY = new Lerper({
      damping: 0.1,
      initialValue: 0,
      onTick(value) {
        listTl.set($list, { y: value });
      },
    });
    $topLevel.toArray().reverse().forEach((topLevel) => {
      const $self = $(topLevel);
      const $ul = $self.children('ul');
      const $a = $self.children('a');

      if (!$ul.length) return;

      let isOpened = false;

      const $dots = $('<button class="dots"></button>').appendTo($a);
      for (let j = 0; j < 3; j += 1) {
        $dots.append('<span class="dot"></span>');
      }

      $ul.css('position', 'absolute');

      const $children = $ul.children();
      const tl = new TimelineMax({ paused: true });
      tl.staggerFromTo($children, 0.2, {
        autoAlpha: 0,
        y: -15,
      }, {
        autoAlpha: 1,
        ease: Linear.easeNone,
        y: 0,
      }, 0.07, '+=0.2');
      const duration = tl.duration();
      const lerper = new Lerper({
        damping: 0.1,
        initialValue: 0,
        onTick(value) {
          tl.seek(duration * value);
        },
      });

      $a.click((e) => {
        e.preventDefault();
        if (!isOpened) {
          $topLevel.not($self).trigger('toggle', false);
        }
        $self.trigger('toggle', !isOpened);
      });

      $self.on('toggle', (e, _isOpened) => {
        if (isOpened === _isOpened) return;

        isOpened = _isOpened;
        $self.nextAll().trigger('move', (isOpened ? 1 : -1) * $ul[0].scrollHeight);
        lerper.setValue(+isOpened);
        $self.toggleClass('opened', isOpened);
        $self.trigger('toggled', isOpened);
      });

      $self.on('moved movedImmediately toggled', (e, y) => {
        const value = listY.getValue();
        const ulCompensation = isOpened ? 0 : $ul[0].scrollHeight;
        const newValue = Math.round(0.5 * ((ulCompensation + value) - Math.abs(y)));
        if (e.type === 'moveImmediately') {
          listY.setValueImmediately(newValue);
        } else {
          listY.setValue(newValue);
        }
      });
    });

    const $models = $list.find('.has-children ul a');
    const $segmenterElement = $modelsGallery.find('[data-role="segmenter"]');
    const segmenterElement = $segmenterElement[0];
    let $prevActiveModel;
    let isBusy = false;

    function setActiveModel($model) {
      if (!$prevActiveModel) {
        $model.addClass('active');
        $prevActiveModel = $model;
      } else if (!isBusy && $model !== $prevActiveModel) {
        const { segmenter } = segmenterElement;
        const { options } = segmenter;
        const tl = new TimelineMax();

        isBusy = true;

        $prevActiveModel.removeClass('active');

        options.animation.duration = 150;
        options.animation.translateZ = 0;
        options.shadowsAnimation.opacity = 0;
        segmenter.active = false;
        segmenter.animate();
        options.shadowsAnimation.opacity = 1;

        tl.to($segmenterElement, 0.15, {
          autoAlpha: 0,
          onComplete() {
            $segmenterElement.html('')
              .css('background-image', `url(${$model.data('src')})`)
              .segmenter((_segmenter) => {
                tl.to($segmenterElement, 0.15, { autoAlpha: 1 });
                _segmenter.animate();
                $model.addClass('active');
                $prevActiveModel = $model;
                isBusy = false;
              });
          },
        });
      }
    }

    setActiveModel($models.first());

    $models.each((i, el) => {
      const $el = $(el);
      $el.mouseenter(() => {
        setActiveModel($el);
      });
    });

    $models.each((i, el) => $(el).html(`<span>${el.innerHTML}</span>`));

    // curtain effect
    $modelsGallery.curtainSection({
      elements: $list.find('a'),
    });
  });
});
