import { TimelineMax, TweenLite } from 'gsap';
import { Power3 } from 'gsap/src/uncompressed/easing/EasePack';
import PerfectScrollbar from 'perfect-scrollbar';
import 'ScrollToPlugin';
import ScrollMagic from 'scrollmagic';
import 'animation.gsap';
import { ROOT_ELEMENT } from '../constants';
import leadZero from '../utils/lead-zero';
import toggleScroll from '../utils/toggle-body-scroll';

jQuery(($) => {
  // hamburger
  $('[data-role*="hamburger"]').each((index, hamburger) => {
    const $self = $(hamburger);
    const $topBar = $('<span />').appendTo($self);
    const $bottomBar = $('<span />').appendTo($self);
    const $bars = $topBar.add($bottomBar);

    let isOpened = false;
    let isBusy = false;
    let hasBeenOnceToggled = false; // fixes a problem when user opens modal
    // faster than enter animation of hamburger

    const tl = new TimelineMax();
    tl.set($topBar, { y: -7 });
    tl.set($bottomBar, { y: 7 });
    tl.set($bars, { scaleX: 0 });

    $self.on('init', () => {
      if (hasBeenOnceToggled) return;
      tl.to($topBar, 0.5, { scaleX: 1 });
      tl.to($bottomBar, 0.2, { scaleX: 0.6 }, '-=0.2');
    });

    function toggle() {
      if (isBusy) return;

      isBusy = true;
      setTimeout(() => {
        isBusy = false;
      }, 700);

      isOpened = !isOpened;

      if (!hasBeenOnceToggled) {
        hasBeenOnceToggled = true;
      }

      if (!$self.data('eventsDisabled')) {
        $self.trigger('toggled', isOpened);
      }

      tl.add('start');

      if (isOpened) {
        tl.to($topBar, 0.2, { scaleX: 1.4, rotation: 45, y: -16 }, 'start');
        tl.to($bottomBar, 0.5, { scaleX: 1.4, rotation: -45, y: 16 }, 'start');
      } else {
        tl.to($topBar, 0.5, { rotation: 0, y: -7, scaleX: 1 }, 'start');
        tl.to($bottomBar, 0.2, { rotation: 0, y: 7, scaleX: 0.6 }, 'start');
      }
    }

    $self.on('toggle click', () => {
      toggle();
    });
  });

  $('[data-role*="header-openable"]').each((index, headerOpenable) => {
    const $self = $(headerOpenable);

    new PerfectScrollbar($self.find('.header-openable__scrollable')[0], {
      suppressScrollX: true,
    });

    $self.find('a').each((i, el) => {
      const $a = $(el);
      $a.html(`<span class="text">${$a.html()}</span>`);
      $a.prepend('<span class="line"></span>');
      $a.prepend(`<span class="index">${leadZero(i + 1)}</span>`);
    });
  });

  // connect hamburger and menu, animation
  $('[data-role="header"]').each((i, header) => {
    const $self = $(header);
    const $hamburger = $self.find('[data-role="hamburger"]');
    const $headerOpenable = $self.find('[data-role="header-openable"]');
    const $links = $headerOpenable.find('a');

    const tl = new TimelineMax();
    tl.set($links, {
      autoAlpha: 0,
      x: -90,
    });

    $hamburger.on('toggled', (e, isOpened) => {
      toggleScroll(isOpened, '.header-openable__scrollable');
      $self.toggleClass('opened', isOpened);

      $headerOpenable.addClass('animate');
      setTimeout(() => {
        $headerOpenable.removeClass('animate');
      }, 700);

      if (isOpened) {
        tl.staggerTo($links, 0.4, {
          autoAlpha: 1,
          x: 0,
        }, -0.1, '+=0.3');
      } else {
        tl.to($links, 0.4, {
          autoAlpha: 0,
          x: -90,
        });
      }
    });

    // appear effect
    const $langStagger = $self.find('[data-role="switch-language"]').find('a, span').not('.circle')
      .add($self.find('.header__phone'));
    const $right = $self.find('.header-right');
    const $rightStagger = $right.find('.header-right-square');
    tl.set($langStagger, { y: -100 });
    tl.set($right, { autoAlpha: 0, scaleY: 0, transformOrigin: 'center top' });
    tl.set($rightStagger, { autoAlpha: 0, y: -15 });
    ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
      tl.staggerTo($langStagger, 0.4, {
        clearProps: 'all',
        y: 0,
      }, 0.15, undefined, () => {
        $self.find('[data-role="switch-language"]').trigger('init');
      });
      tl.to($right, 0.5, {
        autoAlpha: 1,
        clearProps: 'all',
        scaleY: 1,
      }, '-=0.2');
      tl.staggerTo($rightStagger, 0.4, {
        autoAlpha: 1,
        clearProps: 'all',
        y: 0,
      }, -0.15, undefined, () => {
        $self.find('[data-role="hamburger"]').trigger('init');
      });
    });
  });
});

// scroll
jQuery(($) => {
  const controller = new ScrollMagic.Controller();
  const $scrollTo = $('[data-role*="sidebar-scroll-to"]');
  const $footer = $('.footer');

  let coverScene;
  let footerScene;

  if ($scrollTo.length) {
    coverScene = new ScrollMagic.Scene({
      triggerElement: $scrollTo[0],
      triggerHook: 'onLeave',
    }).addTo(controller);
  }

  if ($footer.length) {
    footerScene = new ScrollMagic.Scene({
      triggerElement: $footer[0],
      triggerHook: 'onEnter',
    }).addTo(controller);
  }

  $('[data-role*="scroll-button"]').each((i, el) => {
    const $self = $(el);
    const $text = $self.children('a');
    const $icon = $self.children('i');
    const tl = new TimelineMax();

    let scrollToPosition;

    function toggleScrollButton(isEnabled, isUp, onComplete) {
      if (!isEnabled) {
        scrollToPosition = null;
        $self.css('pointer-events', 'none');
      } else if (isUp) {
        $icon.attr('class', 'icon-arrow_up');
        scrollToPosition = 0;
        $self.css('pointer-events', '');
      } else {
        $icon.attr('class', 'icon-arrow_down');
        scrollToPosition = $scrollTo.length ? $scrollTo.offset().top - 100 : 0;
        $self.css('pointer-events', '');
      }
      tl.to($icon, 0.3, {
        autoAlpha: +isEnabled,
        y: +!isEnabled * 30,
      });
      tl.to($text, 0.4, {
        autoAlpha: +isEnabled,
        onComplete,
        rotation: -90,
        x: 50,
        y: +!isEnabled * 30,
      }, '-=0.2');
    }

    $self.on('toggle', (e, ...args) => toggleScrollButton(...args));
    $self.on('setText', (e, text) => $text.text(text));

    if (coverScene) {
      coverScene.on('enter', () => {
        toggleScrollButton(false, false);
      });

      coverScene.on('leave', () => {
        toggleScrollButton(true, false);
      });
    }

    if (footerScene) {
      footerScene.on('enter', () => {
        toggleScrollButton(true, true);
      });

      footerScene.on('leave', () => {
        toggleScrollButton(false, true);
      });
    }

    $self.click(() => {
      if (scrollToPosition !== null) {
        TweenLite.to(window, 0.8, { scrollTo: { y: scrollToPosition, autoKill: false } });
        toggleScrollButton(false, false);
      }
    });

    if (coverScene) {
      toggleScrollButton(true, false);
    } else {
      toggleScrollButton(false, false);
    }
  });
});
