/**
 * Scroll once effect
 * implementation of the idea from https://oberig.jewelry/philosophy with a block initally
 * covering page and than hiding up with first scroll movement and normal page appearing.
 *
 * Only one element with [data-role="scroll-once"] per page is allowed
 */

import { TimelineMax } from 'gsap';
import { Power1 } from 'gsap/src/uncompressed/easing/EasePack';
import objectFitVideos from 'object-fit-videos';
import normalizeWheel from 'normalize-wheel';
import videojs from 'video.js';
import Hammer from 'hammerjs';
import toggleBodyScroll from '../utils/toggle-body-scroll';
import { ROOT_ELEMENT } from '../constants';

jQuery(($) => {
  const $element = $('[data-role*="scroll-once"]').first();

  if (!$element.length) return;

  const $scrollButton = $('[data-role*="scroll-button"]');
  const scrollButtonTextCache = $scrollButton.text();
  const { skipText = 'Skip' } = $element.data();
  const $nextAll = $element.nextAll();
  const tl = new TimelineMax();

  tl.set($nextAll, {
    autoAlpha: 0,
    y: 120,
  });

  $scrollButton.trigger('setText', skipText);
  $scrollButton.trigger('toggle', [true, false]);

  function hide() {
    tl.add('start');

    tl.to($element, 1, {
      ease: Power1.easeIn,
      y: '-100%',
    });

    tl.to($nextAll, 1, {
      autoAlpha: 1,
      clearProps: 'all',
      onComplete() {
        toggleBodyScroll(false);
        const $videojs = $element.find('video[data-role="videojs"]');
        if ($videojs.length) {
          videojs($videojs[0]).dispose();
        }
      },
      y: 0,
    }, '-=0.4');

    $scrollButton.trigger('toggle', [false, false, () => {
      $scrollButton.trigger('setText', scrollButtonTextCache);
    }]);
  }

  $scrollButton.one('click', hide);

  // hide on mouse devices
  function mouseHandler({ originalEvent }) {
    const { pixelY } = normalizeWheel(originalEvent);
    if (pixelY > 0) {
      hide();
      $(window).off('wheel', mouseHandler);
    }
  }
  $(window).on('wheel', mouseHandler);

  // hide on touch devices
  const mc = new Hammer($element[0]);

  function touchHandler() {
    hide();
    mc.off('panup', touchHandler);
  }

  mc.on('panup', touchHandler);

  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 500);

  ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
    toggleBodyScroll(true);
  });

  objectFitVideos($element.find('video')[0]);
});
