/**
 * Parallax effect
 * Can be applied to any element with data-attributes and doesn't require to change this code.
 *
 * Each parallaxing element should have parent element with data-role="p-measurer"
 * which doesn't change its vertical position with scrolling.
 *
 * The viewport of parent element is used to determine the progress of parallax effect
 * that will be applied to elements with data-role="parallax" inside of it.
 *
 * Element that actually moves should have data-role="parallax" and
 * data-depth="<number>".
 *
 * - depth=1 means  moving in the direction of scrolling with speed equal to scrolling
 * (sticky position)
 * - depth from 0 to 1 - moving in the direction of scrolling (slower)
 * - depth=0 - no effect,
 * - depth less than 0 - moving in the direction opposite to scrolling
 *
 * Optionally:
 * - data-is-horizontal="true"
 * - data-damping="<value from 0 to 1>" - speed of transitions, best values are 0.04 to 0.12
 */

import { TweenLite } from 'gsap';
import Lerper from './utils/lerper';

export default function getShift(measurer, depth) {
  const { top } = measurer.getBoundingClientRect();
  const offsetY = (0.5 * (measurer.scrollHeight - window.innerHeight)) + top;
  return Math.round(offsetY * depth);
}

jQuery(($) => {
  $('[data-role*="parallax"]').each((index, parallaxElement) => {
    const $self = $(parallaxElement);
    const $measurer = $self.closest('[data-role*="p-measurer"]');
    const measurer = $measurer[0];
    const {
      isHorizontal = false,
      depth = 0,
      damping = 0.1,
    } = $self.data();

    const shift = new Lerper({
      damping,
      initialValue: getShift(measurer, depth),
      onTick(value) {
        if (window.innerWidth > 640) {
          TweenLite.set(parallaxElement, {
            [isHorizontal ? 'x' : 'y']: value,
          });
        } else {
          parallaxElement.style.transform = '';
        }
      },
    });

    window.addEventListener('scroll', () => {
      shift.setValue(getShift(measurer, depth));
    });
  });
});
