import { TimelineMax } from 'gsap';
import { ROOT_ELEMENT } from './constants';

jQuery(($) => {
  const $articles = $('[data-role*="article"]');

  if (!$articles.length) return;

  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 500);

  $articles.each((index, article) => {
    const tl = new TimelineMax();
    tl.set(article, { y: 340 });
    tl.set(article.children, { autoAlpha: 0, y: 30 });
    ROOT_ELEMENT.addEventListener('preloaderEnd', () => {
      tl.to(article, 0.8, {
        clearProps: 'all',
        y: 0,
      });
      tl.to(article.children, 0.6, {
        autoAlpha: 1,
        clearProps: 'all',
        y: 0,
      }, '-=0.2');
    });
  });
});
